import {
    createBrowserRouter
} from "react-router-dom"
import { Root } from './Root';
import { Home } from './pages/Home';
import { FCNantes } from './pages/clubs/FCNantes/FCNantes';
import { FCNantesA } from './pages/clubs/FCNantes/FCNantes-A';
import { FCNantesB } from './pages/clubs/FCNantes/FCNantes-B';
import { FCNantesC } from './pages/clubs/FCNantes/FCNantes-C';
import { Orders } from './pages/orders/Orders';

export const rootRouter = createBrowserRouter([
    {
        path: "/",
        element: <Root />,
        children: [
            {
                path: "/",
                element: <Home />
            },
            {
                path: "/fc-nantes",
                element: <FCNantes />,
            },
            {
                path: "/fc-nantes/A",
                element: <FCNantesA />
            },
            {
                path: "/fc-nantes/B",
                element: <FCNantesB />
            },
            {
                path: "/fc-nantes/C",
                element: <FCNantesC />
            },
            {
                path: "/orders",
                element: <Orders />
            }
        ]
    }
])