import { NavBar } from "../components/NavBar";

export const Home = () => {
  return (
    <div>
      <div className="px-10 flex flex-col">
        <a href="/fc-nantes" className="font-medium text-blue-600 dark:text-blue-500 hover:underline">FC Nantes</a>
      </div>
    </div>
  );
}