import { gql, useMutation } from "@apollo/client";
import { useState } from "react";

const UPDATE_ORDER_STATUS = gql`
    mutation($status: String!, $ids: [Float!]!){
        updateOrdersStatus(status: $status, ids: $ids) {
            id
            status
        }
    }
`;

export const ChangeOrderStatus = ({ selectedOrdersIds, refetch, setClearSelectedOrders }: {
    selectedOrdersIds: string[], refetch: () => void, setClearSelectedOrders: (value: boolean) => void
}) => {
    const updateOrdersStatus = useMutation(UPDATE_ORDER_STATUS);
    const [selectedStatus, setSelectedStatus] = useState<string>("");

    const handleChange = (e: any) => {
        setSelectedStatus(e.target.value);
        updateOrdersStatus[0]({
            variables: {
                status: e.target.value,
                ids: selectedOrdersIds.map(id => Number(id))
            },
            onCompleted: async () => {
                setSelectedStatus("");
                setClearSelectedOrders(true);
                console.log('refetch')
                refetch();
            }
        });
    }

    return (
        <div>
            <select value={selectedStatus} onChange={handleChange} disabled={selectedOrdersIds.length === 0} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5">
                <option value="">Sélectionner un statut</option>
                <option value="TO_PRODUCE">À produire</option>
                <option value="IN_PRODUCTION">En production</option>
                <option value="PRODUCED">Produite</option>
                <option value="TO_BE_SHIPPED">À expédier</option>
            </select>
        </div>
    )
}