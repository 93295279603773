import React from 'react';
import ReactDOM from 'react-dom/client';

import "./index.css";
import { RouterProvider } from 'react-router-dom';
import { rootRouter } from './rootRouter';
import { ApolloClient, ApolloProvider, InMemoryCache } from '@apollo/client';
import { serverUrl } from './constants/serverUrl';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const client = new ApolloClient({
  uri: `${serverUrl}/graphql`,
  cache: new InMemoryCache(),
});

root.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <RouterProvider router={rootRouter} />
    </ApolloProvider>
  </React.StrictMode>
);
