import { Link } from "react-router-dom"

export const NavBar = () => {
    return (
        <div className="p-5 flex items-center gap-3">
            <Link to="/">
                <img src="/logo.svg" alt="logo" className="w-15 h-15" />
            </Link>
            <Link to="/orders">Commandes</Link>
        </div>
    )
}