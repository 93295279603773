import { serverUrl } from "../constants/serverUrl";

export const PrintOrders = ({ selectedOrdersIds, refetch }: { selectedOrdersIds: string[], refetch: () => void }) => {
    const disabled = selectedOrdersIds.length === 0;

    const handlePrint = async () => {
        console.log('printing')
        // post to /pdf/generate-pdf
        const response = await fetch(serverUrl + '/pdf/generate-pdf', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ orderIds: selectedOrdersIds }),
        })
        if (response.ok) {
            const responseData = await response.json();
            const pdfBlob = new Blob([new Uint8Array(responseData.pdf.data)], {
                type: 'application/pdf',
            });

            // Créer un URL pour le blob
            const pdfUrl = window.URL.createObjectURL(pdfBlob);

            // Créer un lien <a> pour le téléchargement
            const link = document.createElement('a');
            link.href = pdfUrl;
            link.setAttribute('download', 'designs.pdf');

            // Ajouter le lien au document et cliquer dessus pour démarrer le téléchargement
            document.body.appendChild(link);
            link.click();

            // Nettoyer l'URL après le téléchargement
            window.URL.revokeObjectURL(pdfUrl);
            refetch();
        }
    };

    return (
        <button
            className={`${disabled ?
                'bg-gray-300' :
                'bg-blue-500 hover:bg-blue-700'
                } text-white font-bold py-2 px-4 rounded`}
            disabled={disabled}
            onClick={handlePrint}
        >
            Imprimer designs
        </button>
    )
}