import { Link } from "react-router-dom"

export const DesignCard = ({ imgPath, title, path }: { imgPath: string, title: string, path: string }) => {
    return (
        <Link to={path}>
            <div className="flex flex-col items-center justify-center w-40 border border-gray-300 rounded">
                <img src={imgPath} alt="FC Nantes" className="h-40" />
                <span className="font-semibold">{title}</span>
            </div>
        </Link>
    )
}