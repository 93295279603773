
export enum OrderStatus {
    TO_PRODUCE = 'TO_PRODUCE',
    IN_PRODUCTION = 'IN_PRODUCTION',
    PRODUCED = 'PRODUCED',
    TO_BE_SHIPPED = 'TO_BE_SHIPPED'
}
export const SelectStatus = ({ selectedStatus, setSelectedStatus }: {
    selectedStatus: string[],
    setSelectedStatus: (status: string[]) => void
}) => {
    return (
        <div>
            <div className="flex gap-5">
                <label className="flex items-center gap-2">
                    <input type="checkbox" onChange={(e) => {
                        if (e.target.checked) {
                            setSelectedStatus([...selectedStatus, "TO_PRODUCE"]);
                        } else {
                            setSelectedStatus(selectedStatus.filter(status => status !== "TO_PRODUCE"));
                        }
                    }}
                        checked={selectedStatus.includes("TO_PRODUCE")}
                    />
                    <span>À produire</span>
                </label>
                <label className="flex items-center gap-2">
                    <input type="checkbox" onChange={(e => {
                        if (e.target.checked) {
                            setSelectedStatus([...selectedStatus, "IN_PRODUCTION"]);
                        } else {
                            setSelectedStatus(selectedStatus.filter(status => status !== "IN_PRODUCTION"));
                        }
                    })}
                        checked={selectedStatus.includes("IN_PRODUCTION")}
                    />
                    <span>En production</span>
                </label>
                <label className="flex items-center gap-2">
                    <input type="checkbox" onChange={(e) => {
                        if (e.target.checked) {
                            setSelectedStatus([...selectedStatus, "TO_BE_SHIPPED"]);
                        } else {
                            setSelectedStatus(selectedStatus.filter(status => status !== "TO_BE_SHIPPED"));
                        }
                    }}
                        checked={selectedStatus.includes("TO_BE_SHIPPED")}
                    />
                    <span>À expédier</span>
                </label>
                <label className="flex items-center gap-2">
                    <input type="checkbox" onChange={(e) => {
                        if (e.target.checked) {
                            setSelectedStatus([...selectedStatus, "PRODUCED"]);
                        } else {
                            setSelectedStatus(selectedStatus.filter(status => status !== "PRODUCED"));
                        }
                    }}
                        checked={selectedStatus.includes("PRODUCED")}
                    />
                    <span>Produite</span>
                </label>
            </div>
        </div>
    )
}