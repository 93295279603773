import { useState } from "react"
import useLocalStorageState from "use-local-storage-state"
import { SelectSizeAndQuantity } from "../../../components/SelectSizeAndQuantity"
import { Payload } from "../../../types/Payload"
const inputClassName = "bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"

const TEMPLATE_CODE = "FCNANTES_A"

export const FCNantesA = () => {
    const [cart, setCart] = useLocalStorageState<Payload[]>("cart", {
        defaultValue: [],
    })
    const [name, setName] = useState("")
    const [number, setNumber] = useState("")

    const [size, setSize] = useState("")
    const [quantity, setQuantity] = useState(1)

    const handleAddToCart = () => {
        if(!size){
            alert("Veuillez choisir une taille")
            return
        }

        const item: Payload = {
            size,
            quantity,
            templateCode: TEMPLATE_CODE,
            // CUSTOMIZATION FIELDS TO EDIT (AND ADD INPUTS IN THE FORM)
            fields: [
                {
                    key: "name",
                    value: name,
                },
                {
                    key: "number",
                    value: number,
                },
            ],
        }

        setCart([...cart, item])
        window.history.back()
    }

    return (
        <div className="p-10">
            <div className="flex flex-col gap-5">
                <h1 className="font-bold text-xl">Design A</h1>
                <div>
                    <label className="block mb-2 text-sm font-medium text-gray-900">Nom du joueur</label>
                    <input value={name} onChange={e => setName(e.target.value)} type="text" className={inputClassName} placeholder="Nom du joueur" required />
                </div>
                <div>
                    <label className="block mb-2 text-sm font-medium text-gray-900">Numéro du joueur</label>
                    <input value={number} onChange={e => setNumber(e.target.value)} type="text" className={inputClassName} placeholder="Numéro du joueur" required />
                </div>
                <SelectSizeAndQuantity size={size} quantity={quantity} setQuantity={setQuantity} setSize={setSize} />
            </div>
            <button onClick={handleAddToCart} type="button" className="mt-5 focus:outline-none text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800">
                Ajouter au panier
            </button>
        </div >
    )
}