export const SelectSizeAndQuantity = ({ size, quantity, setQuantity, setSize }: {
    size: string,
    quantity: number,
    setQuantity: (quantity: number) => void,
    setSize: (size: string) => void,
}) => {
    return (
        <div className="flex flex-col gap-5">
            <div>
                <label className="block mb-2 text-sm font-medium text-gray-900">Size</label>
                <select value={size} onChange={e => setSize(e.target.value)} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5">
                    <option selected>Taille des chaussettes</option>
                    <option value="S">S</option>
                    <option value="M">M</option>
                    <option value="L">L</option>
                    <option value="XL">XL</option>
                </select>
            </div>
            <div>
                <label className="block mb-2 text-sm font-medium text-gray-900">Quantity</label>
                <input value={quantity} onChange={e => setQuantity(parseInt(e.target.value))} type="number" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" />
            </div>
        </div>
    )
}