export const PairsInQueue = ({ orders }: { orders: any }) => {
    const ordersToProduce = orders.filter((order: any) => order.status === 'TO_PRODUCE')
    const pairsToProduceQty = ordersToProduce.reduce((acc: number, order: any) => {
        const orderQty = order.payload.reduce((acc: number, item: any) => {
            return acc + item.quantity
        }, 0)
        return acc + orderQty
    }, 0)

    const ordersInProduction = orders.filter((order: any) => order.status === 'IN_PRODUCTION')
    const pairsInProductionQty = ordersInProduction.reduce((acc: number, order: any) => {
        const orderQty = order.payload.reduce((acc: number, item: any) => {
            return acc + item.quantity
        }, 0)
        return acc + orderQty
    }, 0)

    return (
        <div className="flex gap-5">
            <div>
                <span className="font-semibold">Paires à produire</span><span> : {pairsToProduceQty}</span>
            </div>
            <div>
                <span className="font-semibold">Paires en production </span><span>: {pairsInProductionQty}</span>
            </div>
        </div>
    )

}