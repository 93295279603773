import useLocalStorageState from "use-local-storage-state"
import { Payload } from "../types/Payload"
import { gql, useMutation } from "@apollo/client"
import { useEffect, useState } from "react"
import useWebSocket from "react-use-websocket"
import { socketUrl } from "../constants/socketUrl"

const CREATE_ORDER = gql`
    mutation($payload: String!, $name: String, $phone: String, $email: String, $adress: String, $zipCode: String, $city: String, $comment: String){
        createOrder(payload: $payload, name: $name, phone: $phone, email: $email, adress: $adress, zipCode: $zipCode, city: $city, comment: $comment) {
            id
        }
    }
`

const inputClassName = "bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"

export const Cart = () => {
    const [showCustomerForm, setShowCustomerForm] = useState(false)
    const [customerName, setCustomerName] = useState("")
    const [customerEmail, setCustomerEmail] = useState("")
    const [customerPhone, setCustomerPhone] = useState("")
    const [customerAddress, setCustomerAddress] = useState("")
    const [customerZipCode, setCustomerZipCode] = useState("")
    const [customerCity, setCustomerCity] = useState("")
    const [comment, setComment] = useState("")

    const clearCustomerForm = () => {
        setCustomerName("")
        setCustomerEmail("")
        setCustomerPhone("")
        setCustomerAddress("")
        setCustomerZipCode("")
        setCustomerCity("")
        setComment("")
    }


    const [cart, setCart] = useLocalStorageState<Payload[]>("cart", {
        defaultValue: [],
    })
    const [success, setSuccess] = useState(false)
    const [createOrder, { data, loading, error }] = useMutation(CREATE_ORDER)
    const { sendMessage, readyState } = useWebSocket(socketUrl);
    useEffect(() => {
        console.log("readyState", readyState)
    }, [readyState])

    const handleCreateOrder = async () => {
        if (cart.length === 0) return
        await createOrder({
            variables: {
                payload: JSON.stringify(cart),
                name: customerName,
                phone: customerPhone,
                email: customerEmail,
                adress: customerAddress,
                zipCode: customerZipCode,
                city: customerCity,
                comment
            },
            onCompleted: (data) => {
                setCart([])
                setSuccess(true)
                sendMessage(JSON.stringify({ type: "order", payload: { orderId: data.createOrder?.id } }))
                clearCustomerForm()
            },
            onError: (error) => {
                console.error(error.message)
            }
        })
    }

    return (
        <div className="mt-5 border p-2 rounded">
            <h1 className="font-semibold mb-2">Panier</h1>
            {success && <p className="font-semibold text-green-600">Commande passée avec succès</p>}
            {cart.length === 0 && <span>Le panier est vide</span>}
            <div>
                {
                    cart.map((item, index) => {
                        return (
                            <div key={index} className="flex gap-4 border p-2 rounded mb-3">
                                <button onClick={() => {
                                    const newCart = cart.filter((_, i) => i !== index)
                                    setCart(newCart)
                                }} className="bg-red-500 text-white p-1 rounded">Supprimer</button>
                                <span className="font-semibold">{item.templateCode}</span>
                                <span>Quantité : {item.quantity}</span>
                                <span>Taille : {item.size}</span>
                                <span>[</span>
                                {
                                    item.fields.map((field, index) => {
                                        return (
                                            <div>
                                                <span className="font-semibold">{field.key}</span> : <span>{field.value}</span>
                                            </div>
                                        )
                                    })
                                }
                                <span>]</span>
                            </div>
                        )
                    })
                }
            </div>
            <button onClick={() => {
                setShowCustomerForm(!showCustomerForm)
                if (!showCustomerForm) {
                    clearCustomerForm()
                }
            }} className="text-blue-500 p-1 rounded">Ajouter les informations du client</button>
            <div>
                {
                    showCustomerForm &&
                    (<div className="flex flex-col gap-3 p-3 border rounded-lg mb-5">
                        <h2>Informations du client</h2>
                        <label className="block mb-2 text-sm font-medium text-gray-900">Email du client</label>
                        <input value={customerEmail} onChange={e => setCustomerEmail(e.target.value)} type="text" className={inputClassName} placeholder="Email du client" />
                        <label className="block mb-2 text-sm font-medium text-gray-900">Nom du client</label>
                        <input value={customerName} onChange={e => setCustomerName(e.target.value)} type="text" className={inputClassName} placeholder="Nom du client" />
                        <label className="block mb-2 text-sm font-medium text-gray-900">Téléphone du client</label>
                        <input value={customerPhone} onChange={e => setCustomerPhone(e.target.value)} type="text" className={inputClassName} placeholder="Téléphone du client" />
                        <label className="block mb-2 text-sm font-medium text-gray-900">Adresse du client</label>
                        <input value={customerAddress} onChange={e => setCustomerAddress(e.target.value)} type="text" className={inputClassName} placeholder="Adresse du client" />
                        <label className="block mb-2 text-sm font-medium text-gray-900">Code postal du client</label>
                        <input value={customerZipCode} onChange={e => setCustomerZipCode(e.target.value)} type="text" className={inputClassName} placeholder="Code postal du client" />
                        <label className="block mb-2 text-sm font-medium text-gray-900">Ville du client</label>
                        <input value={customerCity} onChange={e => setCustomerCity(e.target.value)} type="text" className={inputClassName} placeholder="Ville du client" />
                        <label className="block mb-2 text-sm font-medium text-gray-900">Commentaire</label>
                        <textarea className={inputClassName} placeholder="Commentaire" value={comment} onChange={e => setComment(e.target.value)} />

                    </div>)
                }
                {cart.length > 0 && <button onClick={handleCreateOrder} className="bg-blue-500 text-white p-1 rounded">Passer la commande</button>}
                {loading && <span>Envoi de la commande...</span>}
                {error && <span className="text-red-600">{error.message}</span>}
            </div>
        </div>
    )
}