import { useState } from "react";
import { serverUrl } from "../constants/serverUrl";

export const PrintTimbres = ({ selectedOrdersIds, refetch }: { selectedOrdersIds: string[], refetch: () => void }) => {
  const [timbresLoading, setTimbresLoadings] = useState(false);
  const [timbresErrors, setTimbresErrors] = useState<string[]>([]);

  const disabled = selectedOrdersIds.length === 0;

  const handlePrint = async () => {
    setTimbresErrors([]);
    try {
      setTimbresLoadings(true);
      // Récupérer les IDs des commandes sélectionnées
      const orderIds = selectedOrdersIds

      // Créer un objet contenant les données à envoyer dans le corps de la requête
      const requestBody = JSON.stringify({ orderIds });

      // Envoyer une requête POST au serveur pour générer les PDF des timbres
      const response = await fetch(`${serverUrl}/delivery/generate-timbres`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: requestBody,
      });

      // Vérifier si la requête a réussi (statut HTTP 200)
      if (response.ok) {
        const responseData = await response.json();
        if (responseData.errors && responseData.errors.length > 0) {
          setTimbresErrors(responseData.errors);
        }

        // Créer un blob à partir des données buffer
        const pdfBlob = new Blob([new Uint8Array(responseData.stickers.data)], {
          type: 'application/pdf',
        });

        // Créer un URL pour le blob
        const pdfUrl = window.URL.createObjectURL(pdfBlob);

        // Créer un lien <a> pour le téléchargement
        const link = document.createElement('a');
        link.href = pdfUrl;
        link.setAttribute('download', 'timbre.pdf');

        // Ajouter le lien au document et cliquer dessus pour démarrer le téléchargement
        document.body.appendChild(link);
        link.click();

        // Nettoyer l'URL après le téléchargement
        window.URL.revokeObjectURL(pdfUrl);
      } else {
        // Gérer l'erreur
        setTimbresErrors([
          `La requête a échoué avec le statut: + ${response.status}`,
        ]);
      }
      setTimbresLoadings(false);
      refetch();
    } catch (error) {
      // Gérer l'erreur
      console.error('Erreur:', error);
      setTimbresLoadings(false);
    }
  }

  return (
    <>
      <button
        className={`${timbresLoading || disabled ?
          'bg-gray-300' :
          'bg-blue-500 hover:bg-blue-700'
          } text-white font-bold py-2 px-4 rounded`}
        disabled={timbresLoading || disabled}
        onClick={handlePrint}
      >
        {timbresLoading ? "Chargement" : "Imprimer Timbres"}
      </button>
      {timbresErrors.length > 0 && (
        <div className="mt-3">
          {timbresErrors.map((error, index) => (
            <p key={index} className="text-red-500">{error}</p>
          ))}
        </div>
      )}
    </>
  )
}

