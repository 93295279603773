import { Cart } from "../../../components/Cart"
import { DesignCard } from "../../../components/DesignCard"

export const FCNantes = () => {
    return (
        <div className="p-5">
            <div className="flex gap-5">
                <DesignCard imgPath="/fc-nantes/fc-nantes-A.png" title="Design A" path="/fc-nantes/A" />
                <DesignCard imgPath="/fc-nantes/fc-nantes-B.png" title="Design B" path="/fc-nantes/B" />
                <DesignCard imgPath="/fc-nantes/fc-nantes-C.png" title="Design C" path="/fc-nantes/C" />
            </div>
            <div>
                <Cart />
            </div>
        </div>
    )
}